
<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card>
          <b-row class="mb-3">
            <b-col>
              <h5 class="mt-0 mb-1">
                {{ $t('fields.profile') }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="pt-2">
            <b-col
              cols="6"
            >
              <small class="mb-1">
                <feather-icon
                  icon="CalendarIcon"
                />
                {{ $t('user.registered_date') }}
              </small>
              <p class="text-muted">
                {{ playerDetail.createdAt | datetime }}
              </p>
            </b-col>
            <b-col
              cols="6"
            >
              <small class="mb-1">
                <feather-icon
                  icon="CalendarIcon"
                />
                {{ $t('fields.lastLogin') }}
              </small>
              <p class="text-muted">
                {{ playerDetail.lastLoggedInAt | datetime }}
              </p>
            </b-col>
          </b-row>
          <b-row class="pt-2">
            <b-col
              cols="6"
            >
              <small class="mb-1">
                <feather-icon
                  icon="UserCheckIcon"
                />
                {{ $t('user.referral') }}
              </small>
              <p class="text-muted">
                {{ playerDetail.affiliateByUsername || '-' }}
              </p>
            </b-col>
            <b-col
              cols="6"
            >
              <small class="mb-1">
                <feather-icon
                  icon="ShieldIcon"
                />
                {{ $t('agent.title') }}
              </small>
              <p class="text-muted">
                {{ playerDetail.agentName || '-' }}
              </p>
            </b-col>
          </b-row>
          <PlayerProfileForm />
        </b-card>
      </b-col>
      <!-- player summary -->
      <b-col
        cols="12"
        md="6"
      >
        <b-card>
          <h5 class="mt-0 mb-3">
            {{ $t('routes.VIEW_SUMMARY') }}
          </h5>
          <b-link
            :to="`/credits/all?search=${playerDetail.profile.username}`"
            class="float-right"
          >
            {{ $t('credits.credit_transaction') }}
            <feather-icon
              icon="ArrowUpRightIcon"
              class="ml-1"
            />
          </b-link>
          <h6>
            <feather-icon
              icon="DollarSignIcon"
              class="mr-1"
            />
            {{ $t('credits.credit') }}
          </h6>
          <b-row>
            <b-col
              cols="3"
              md="6"
            >
              <small class="mb-1">
                {{ $t('credits.current') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.credit | currency }}
              </p>
            </b-col>
            <b-col
              cols="3"
              md="6"
            >
              <small class="mb-1">
                {{ $t('credits.hold_balance') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.holdCredit | currency }}
              </p>
            </b-col>
            <b-col
              cols="3"
              md="6"
            >
              <small class="mb-1">
                {{ $t('credits.deposit_amount') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.totalDeposit | currency }}
              </p>
            </b-col>
            <b-col
              cols="3"
              md="6"
            >
              <small class="mb-1">
                {{ $t('credits.withdraw_amount') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.totalWithdraw | currency }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <small class="mb-1">
                {{ $t('credits.bonus') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.totalBonus | currency }}
              </p>
            </b-col>
            <b-col cols="6">
              <small class="mb-1">
                {{ $t('credits.margin') }}
              </small>
              <p class="text-muted">
                n/a
                <!-- {{ 0 | currency }} -->
              </p>
            </b-col>
          </b-row>

          <h6>
            <feather-icon
              icon="UserIcon"
            />
            {{ $t('affiliate.title') }}
          </h6>
          <b-row>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('affiliate.tabs.members') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.affiliateMemberCount | money }}
                N/A
              </p>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('affiliate.tabs.credits') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.affiliateCredit | currency }}
              </p>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('affiliate.tabs.total_credit') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.affiliateTotalCredit | currency }}
              </p>
            </b-col>
          </b-row>
          <h6>
            Cashback
          </h6>
          <b-row>
            <b-col cols="4">
              <small class="mb-1">
                ยอดแทงทั้งหมด
              </small>
              <p class="text-muted">
                <!-- {{ | money }} -->
                N/A
              </p>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('affiliate.tabs.credits') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.cashbackCredit | currency }}
              </p>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('affiliate.tabs.total_credit') }}
              </small>
              <p class="text-muted">
                {{ playerSummary.cashbackTotalCredit | currency }}
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-card>
          <h5 class="mt-0 mb-3">
            {{ $t('routes.VIEW_BANK_ACCOUNTS') }}
          </h5>
          <div>
            <PlayerBankAccountForm />
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h5 class="mt-0 mb-3">
            {{ $t('user.password') }}
          </h5>
          <div>
            <PlayerPasswordForm />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <h5>รายได้ค่าแนะนำ</h5>
      <b-table
        :items="afData"
        :fields="afFields"
        :busy="isFetchingPlayerAf"
        responsive
        show-empty
      >
        <template #cell(date)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(income)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(betAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(withdraw)="data">
          {{ data.value | currency }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{ $t('messages.nothing_here') }}
          </p>
        </template>
      </b-table>
    </b-card>
    <b-card>
      <h5>ประวัติการถอน Cashback</h5>
      <b-table
        :items="cashbackData"
        :fields="cashbackFields"
        :busy="isFetchingPlayerCashback"
        responsive
        show-empty
      >
        <template #cell(date)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(income)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(betAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(withdraw)="data">
          {{ data.value | currency }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{ $t('messages.nothing_here') }}
          </p>
        </template>
      </b-table>
    </b-card>
    <b-card>
      <h5>
        {{ $t('promotion.history') }}
      </h5>
      <PlayerPromotionRedemptionHistory :player-id="playerId" />
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Player detail',
  },
  components: {
    PlayerProfileForm: () => import('./components/PlayerProfileForm.vue'),
    PlayerBankAccountForm: () => import('./components/PlayerBankAccountForm.vue'),
    PlayerPasswordForm: () => import('./components/PlayerPasswordForm.vue'),
    PlayerPromotionRedemptionHistory: () => import('./components/PlayerRedemptionHistory.vue'),
  },
  data() {
    return {
      afFields: [
        {
          key: 'date',
          label: 'วันที่',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'betAmount',
          label: 'ยอดแทง',
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'income',
          label: 'รายได้',
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        { 
          key: 'withdraw',
          label: 'ยอดถอน',
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
      ],
      cashbackFields: [
        {
          key: 'date',
          label: 'วันที่',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'betAmount',
          label: 'ยอดแทง',
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'income',
          label: 'รายได้',
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
        { 
          key: 'withdraw',
          label: 'ยอดถอน',
          class: 'text-right',
          thStyle: {
            minWidth: '160px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingPlayer: (state) => state.player.isFetchingPlayer,
      isFetchingPlayerAf: (state) => state.player.isFetchingPlayerAf,
      isFetchingPlayerCashback: (state) => state.player.isFetchingPlayerCashback,
    }),
    ...mapGetters(['playerDetail', 'playerCashbackLogs', 'playerAfLogs']),
    playerId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    playerSummary() {
      return this.playerDetail.summary
    },
    afData() {
      return this.playerAfLogs
    },
    cashbackData() {
      return this.playerCashbackLogs
    },
  },
  created() {
    this.onFetch()
  },
  methods: {
    ...mapActions([
      'fetchPlayer',
      'deletePlayer',
      'fetchPlayerAfLogs',
      'fetchPlayerCashbackLogs',
    ]),
    onFetch() {
      if (this.playerId) {
        this.fetchPlayer(this.playerId)
        this.fetchPlayerAfLogs(this.playerId)
        this.fetchPlayerCashbackLogs(this.playerId)
      }
    },
    displayStatusColor() {
      if (this.playerDetail.is_verified) {
        return 'success'
      } else if (this.playerDetail.is_banned || this.playerDetail.is_deleted) {
        return 'danger'
      }
      return 'light'
    },
    onDeletePlayer() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deletePlayer(this.playerId)
          }
        })
    },
    async cancelPromotion() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        `${this.$t('messages.confirm')}`,
        {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        }
      )
      if (confirm) {
        // do
      }
      this.fetchPlayer(this.playerId)
    },
    async onClearPromotionReset() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        'การเคลียร์โปรโมชั่นจากการกระทำนี้ เป็นการเคลียร์โดยข้ามเงื่อนไขระบบบางอย่าง กรุณาตรวจสอบข้อมูลให้รอบคอบก่อนใช้งาน',
        {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        }
      )
      if (confirm) {
        // do
      }
    },
  },
}
</script>
